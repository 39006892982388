import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {TranscriptService} from "../transcript.service";
import {TranscriptInfo, TranscriptStatus} from "../transcript-info";

@Component({
  selector: 'app-transcript-detail',
  templateUrl: './transcript-detail.component.html',
  styleUrls: ['./transcript-detail.component.css']
})
export class TranscriptDetailComponent implements OnInit, OnDestroy {
  sourceImage: SafeUrl;
  transcriptText: string;
  transcriptInfo: TranscriptInfo;

  constructor(private transcriptService : TranscriptService, private route: ActivatedRoute, private location: Location,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.getTranscript();
  }

  ngOnDestroy() {
  }

  getTranscript() {
    const id = this.route.snapshot.paramMap.get('id');
    this.transcriptService.fetchTranscript(id).subscribe(data => {
      if (data) {
        this.handleLoad(data);
      }
    })
  }

  handleLoad(ti: TranscriptInfo) {
    this.transcriptInfo = ti;
    this.transcriptService.fetchImage(ti.id).subscribe(data => {
      if (data) {
        this.createImageFromBlob(data);
      }
    });

    if (ti.status == TranscriptStatus.done) {
      this.transcriptService.fetchText(ti.id).subscribe(data => {
        this.transcriptText = data;
      });
    }
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.sourceImage = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  goBack() {
    this.location.back();
  }

  download() {
    window.location.href = `/rest/transcript/download/${this.transcriptInfo.resultFile}?id=${this.transcriptInfo.id}`;
  }

}

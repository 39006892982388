import {Component, Input, OnInit} from '@angular/core';
import {TranscriptInfo} from "../transcript-info";

@Component({
  selector: 'app-transcript-info',
  templateUrl: './transcript-info.component.html',
  styleUrls: ['./transcript-info.component.css']
})
export class TranscriptInfoComponent implements OnInit {
  @Input() transcriptInfo : TranscriptInfo;

  constructor() { }

  ngOnInit() {
  }

}

import {Component, OnInit} from '@angular/core';
import {TranscriptService} from "../transcript.service";

@Component({
  selector: 'app-single-transcript',
  templateUrl: './single-transcript.component.html',
  styleUrls: ['./single-transcript.component.css']
})
export class SingleTranscriptComponent implements OnInit {
  fileToUpload: File = null;
  uploading : boolean = false;

  constructor(private transcriptService: TranscriptService) {
  }

  ngOnInit() {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  upload() {
    if (this.fileToUpload) {
      this.uploading = true;
      this.transcriptService.postFile(this.fileToUpload).subscribe(data => {
        this.transcriptService.startListening(data);
        this.uploading = false;
      })
    } else {
      alert("Please select an image to transcript!");
    }
  }
}

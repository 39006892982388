export class TranscriptInfo {
  id : string;
  status: TranscriptStatus;
  messages: Array<string>;
  created: string;
  started : string;
  finished : string;
  sourceFile : string;
  resultFile : string;
}

export enum TranscriptStatus {
  waiting = "waiting", running = "running", failed = "failed", done = "done"
}

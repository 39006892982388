import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SingleStatusResult} from "./single-status-result";
import {SingleUploadResult} from "./single-upload-result";
import {TranscriptInfo, TranscriptStatus} from "./transcript-info";
import {Router} from '@angular/router';
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TranscriptService {
  jobId : string = null;
  intervalHolder : any;
  messages : string = '';

  constructor(private httpClient: HttpClient, private router: Router) { }

  postFile(fileToUpload: File) : Observable<SingleUploadResult> {
    const endpoint = '/rest/upload-single';
    const formData: FormData = new FormData();
    formData.append('image', fileToUpload, fileToUpload.name);
    return this.httpClient.post<SingleUploadResult>(endpoint, formData).pipe(
      catchError(this.handleError<SingleUploadResult>("Error uploading files", null))
    );
  }

  postFiles(batchName: string, filesToUpload: File[]) : Observable<any> {
    const endpoint = '/rest/upload-multiple';
    const formData: FormData = new FormData();
    formData.append("name", batchName);
    for (let i=0; i<filesToUpload.length; i++) {
      formData.append('image', filesToUpload[i], filesToUpload[i].name);
    }
    return this.httpClient.post<any>(endpoint, formData).pipe(
      catchError(this.handleError<any>("Error uploading files", null))
    );
  }

  startListening(data: SingleUploadResult) {
    if (data) {
      this.jobId = data.id;
      this.intervalHolder = setInterval(() => {
        this.status();
      }, 1000);
    }
  }

  status() {
    this.httpClient.get<SingleStatusResult>(`/rest/status-single/${this.jobId}`)
      .subscribe(data => {
        if (data.status == TranscriptStatus.done || data.status == TranscriptStatus.failed) {
          clearInterval(this.intervalHolder);
          if (data.status == TranscriptStatus.done) {
            this.router.navigate(['/detail', this.jobId]);
          } else {
            alert("Problems occurred on transcript.")
          }
        }
        this.messages = data.message;
    }, error => {
        console.log("Error getting status", error);
        clearInterval(this.intervalHolder);
      });
  }

  fetchImage(id: string): Observable<Blob>  {
    const blobHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'blob' as 'json'
    };

    return this.httpClient.get<Blob>(`/rest/transcript/${id}/image`, blobHttpOptions).pipe(
      catchError(this.handleError<Blob>("Error fetching image.", null))
    );
  }

  fetchText(id: string): Observable<string> {
    const textHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'text' as 'json'
    };

    return this.httpClient.get<string>(`/rest/transcript/${id}/text`, textHttpOptions).pipe(
      catchError(this.handleError<string>(null, "Error fetching text"))
    );
  }

  transcripts(batchId: string): Observable<TranscriptInfo []> {
    return this.httpClient.get<TranscriptInfo []>(`/rest/transcripts/${batchId}`).pipe(
      catchError(this.handleError<TranscriptInfo []> ('Error getting transcripts!', []))
    )
  }

  batches(): Observable<string[][]> {
    return this.httpClient.get<string[][]>('/rest/batches').pipe(
      catchError(this.handleError<string[][]> ('Error getting batches!', []))
    )
  }

  fetchTranscript(id : string): Observable<TranscriptInfo> {
    return this.httpClient.get<TranscriptInfo>(`/rest/transcript/${id}/info`).pipe(
      catchError(this.handleError<TranscriptInfo>('Error getting transcript info', null))
    );
  }

  private handleError<T> (message: string, result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      if (message) {
        alert(message);
      }
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SingleTranscriptComponent} from "./single-transcript/single-transcript.component";
import {MultipleTranscriptComponent} from "./multiple-transcript/multiple-transcript.component";
import {TranscriptListComponent} from "./transcript-list/transcript-list.component";
import {TranscriptDetailComponent} from "./transcript-detail/transcript-detail.component";

const routes: Routes = [
  {path: 'single', component: SingleTranscriptComponent},
  {path: 'multiple', component: MultipleTranscriptComponent},
  {path: 'list/:batchId', component: TranscriptListComponent},
  {path: '', component: SingleTranscriptComponent, pathMatch: 'full'},
  {path: 'detail/:id', component: TranscriptDetailComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SingleTranscriptComponent } from './single-transcript/single-transcript.component';
import { MultipleTranscriptComponent } from './multiple-transcript/multiple-transcript.component';
import {HttpClientModule} from "@angular/common/http";
import { TranscriptListComponent } from './transcript-list/transcript-list.component';
import { TranscriptDetailComponent } from './transcript-detail/transcript-detail.component';
import { TranscriptInfoComponent } from './transcript-info/transcript-info.component';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    AppComponent,
    SingleTranscriptComponent,
    MultipleTranscriptComponent,
    TranscriptListComponent,
    TranscriptDetailComponent,
    TranscriptInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {TranscriptInfo, TranscriptStatus} from "../transcript-info";
import {TranscriptService} from "../transcript.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-transcript-list',
  templateUrl: './transcript-list.component.html',
  styleUrls: ['./transcript-list.component.css']
})
export class TranscriptListComponent implements OnInit {
  transcripts$ : Observable<Array<TranscriptInfo>>;
  transcript: TranscriptInfo;
  batchId: string;
  batches: string[][];

  constructor(private transcriptService : TranscriptService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.batchId = this.route.snapshot.paramMap.get('batchId');
    this.transcriptService.batches().subscribe(data => {
      this.batches = data;
    });
    this.refreshList();
  }

  itemSelected(transcript: TranscriptInfo) {
    if (transcript.status == TranscriptStatus.done) {
      this.router.navigate(['/detail', transcript.id]);
    } else {
      this.transcriptInfo(transcript);
    }
  }

  transcriptInfo(transcript: TranscriptInfo) {
    this.transcript = transcript;
  }

  refreshList() {
    this.transcripts$ = this.transcriptService.transcripts(this.batchId);
  }
}

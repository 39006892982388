import {Component, OnInit} from '@angular/core';
import {TranscriptService} from "../transcript.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-multiple-transcript',
  templateUrl: './multiple-transcript.component.html',
  styleUrls: ['./multiple-transcript.component.css']
})
export class MultipleTranscriptComponent implements OnInit {
  batchName: string;
  files: FileInfo[] = [new FileInfo()];
  uploading: boolean = false;

  constructor(private transcriptService: TranscriptService, private router: Router) { }

  ngOnInit() {
  }

  handleFileInput(i, files: FileList) {
    let filesArr: File [] = [];
    for (let i=0; i<files.length; i++) {
      filesArr.push(files.item(i));
    }
    this.files[i].files = filesArr;
  }

  upload() {
    if (!this.batchName) {
      alert("A name must be specified!");
      return;
    }
    let filesToUpload = [];
    for (let i=0; i<this.files.length; i++) {
      let iterFiles = this.files[i].files;
      if (iterFiles) {
        for (let j=0; j<iterFiles.length; j++) {
          filesToUpload.push(iterFiles[j]);
        }
      }
    }

    if (filesToUpload.length == 0) {
      alert("Please select at least one image!");
      return;
    }

    this.uploading = true;
    this.transcriptService.postFiles(this.batchName, filesToUpload).subscribe(data => {
      if (data) {
        this.router.navigate(['/list', data.batchId]);
      }
      this.uploading = false;
    });
  }

  remove(i) {
    this.files.splice(i, 1);
  }

  add() {
    this.files.push(new FileInfo());
  }
}

class FileInfo {
  files: File[];
}
